import { LitElement, css, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { styles } from '../../public/styles/shared-styles';
import { router, resolveRouterPath } from '../router';
import { requestPermission } from './firebase-config';
import './app-login';
import './app-batting';
import './app-availability';
import './app-events';
import './app-results-fixtures-carousel.js';
import { getAuth, onAuthStateChanged,  User } from 'firebase/auth';
import {
  collection,
  getFirestore,
  getDocs,
  query,
  orderBy,
  limit,
  doc,
  getDoc,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager
} from 'firebase/firestore';
import { getApp } from 'firebase/app';






let firestoreInitialized = false;

@customElement('app-home')
export class AppHome extends LitElement {
  @state() isSidebarOpen = false;
  @state() isLoggedIn = false;
  @state() firstName = '';
  @state() user: User | null = null;
  @state() clubRunsScored = 0;
  @state() clubWickets = 0;
  @state() clubGames = 0;
  @state() clubCatches = 0;
  @state() userType = '';
  @state() newsItems: Array<any> = [];
  @state() isLoadingMore = false;
  @state() lastVisible: any = null;
  @state() activeTab: string = 'news'; // Default to 'news'

  static styles = [
    styles,
    css`
      :host {
        --sidebar-width: 250px;
        background-image: url('/assets/images/background.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 100vh;
        margin: 0;
        padding: 0;
        display: block;
        width: 100%;
        overflow-x: hidden;
      }
      body {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        overflow-x: hidden;
      }
      /* Main container centered with responsive width */
      .container {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 16px;
        box-sizing: border-box;
      }
      /* Tabs styling */
      .tabs {
        display: flex;
        justify-content: space-around;
        background-color: #4CAF50;
        margin: 0 auto;
        max-width: 1200px;
      }
      .tab {
        padding: 15px;
        cursor: pointer;
        color: white;
        font-weight: bold;
        flex: 1;
        text-align: center;
      }
      .tab.active {
        background-color: #2E7D32;
      }
      .top-stats {
        padding-top: 115px;
      }
      /* Availability tab content: reduce top gap */
      .tab-content {
        margin-top: 10px;
      }
      /* News feed vertical stacking */
      .news-feed {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 20px;
      }
      .stat-card {
        background-color: white;
        border-radius: 10px;
        padding: 20px;
        text-align: left;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease;
        width: 100%;
        color: #206e3a;
        box-sizing: border-box;
      }
      .news-date {
        font-size: 0.85rem;
        color: #666;
        margin-top: 4px;
      }
      /* Important message container */
      .important-message-container {
        background-color: white;
        padding: 15px;
        text-align: center;
        border-radius: 8px;
        margin: 20px auto;
        max-width: 1200px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      }
      .important-message-container .message {
        font-size: 1.2rem;
        color: #206e3a;
      }
      .link-to-awards {
        color: #007bff;
        text-decoration: none;
        font-weight: bold;
      }
      .link-to-awards:hover {
        color: #0056b3;
        text-decoration: underline;
      }
      h3 {
        color: #206e3a;
        font-size: 1.5em;
        text-align: center;
      }
      .skeleton {
        background-color: #ddd;
        animation: shimmer 1.2s infinite linear;
        height: 20px;
        width: 100%;
        border-radius: 4px;
      }
      @keyframes shimmer {
        0% { background-position: -100%; }
        100% { background-position: 100%; }
      }
      /* Mobile adjustments */
      @media (max-width: 600px) {
        .container {
          padding: 0 8px;
        }
        .tabs {
          flex-direction: column;
        }
        .tab {
          padding: 12px;
          font-size: 0.9rem;
        }
        .news-feed {
          gap: 12px;
        }
      }
 .notify-btn {
      background-color: #206e3a;
      color: #fff;
      border: none;
      border-radius: 4px;
      padding: 12px 24px;
      font-size: 1rem;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }
    .notify-btn:hover {
      background-color: #164d29;
    }

    `,
  ];

  connectedCallback() {
    super.connectedCallback();
    this.initAuth();
    this.fetchClubStats();
    this.loadNewsBatch();
    this.setupFirestoreWithPersistence();
    this.isSidebarOpen = false;
  }

  setupFirestoreWithPersistence() {
    const app = getApp();
    if (!firestoreInitialized) {
      initializeFirestore(app, {
        localCache: persistentLocalCache({ tabManager: persistentMultipleTabManager() }),
      });
      firestoreInitialized = true;
    } else {
      getFirestore(app);
    }
  }

  initAuth() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.user = user;
        this.isLoggedIn = true;
        this.fetchUserName(user.uid);
      } else {
        this.user = null;
        this.isLoggedIn = false;
        if (window.location.pathname !== resolveRouterPath('home')) {
          router.navigate(resolveRouterPath('login'));
        }
      }
    });
  }

  private async enableNotifications(): Promise<void> {
    await requestPermission();
  }

  async fetchUserName(userId: string) {
    const db = getFirestore();
    const userRef = doc(db, `Player/${userId}`);
    try {
      const docSnap = await getDoc(userRef);
      if (docSnap.exists()) {
        const userData = docSnap.data();
        if (userData.first_name) {
          this.firstName = userData.first_name;
        }
        this.userType = userData.type || 'Player';
      } else {
        this.firstName = 'User';
        this.userType = 'Player';
      }
    } catch (error) {
      console.error('Error fetching user document:', error);
    }
  }

  async fetchClubStats() {
    const db = getFirestore();
    const statsRef = doc(db, "Club/Stats");
    try {
      const docSnap = await getDoc(statsRef);
      if (docSnap.exists()) {
        const statsData = docSnap.data();
        this.clubRunsScored = statsData.runs_scored || 0;
        this.clubWickets = statsData.wickets || 0;
        this.clubGames = statsData.games || 0;
        this.clubCatches = statsData.catches || 0;
      }
    } catch (error) {
      console.error('Error fetching club stats:', error);
    }
  }

  async loadNewsBatch() {
    const db = getFirestore();
    const newsQuery = query(collection(db, 'News'), orderBy('date', 'desc'), limit(5));
    const newsSnapshot = await getDocs(newsQuery);
    const newsData = newsSnapshot.docs.map((doc) => {
      const data = doc.data();
      return { ...data, id: doc.id };
    });
    this.newsItems = newsData;
  }

  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
  }

  changeTab(tab: string) {
    this.activeTab = tab;
  }

  renderTabs() {
    const tabs = ['News', 'Availability', 'Events'];
    return html`
      <div class="tabs">
        ${tabs.map(tab => html`
          <div class="tab ${this.activeTab === tab.toLowerCase() ? 'active' : ''}" @click="${() => this.changeTab(tab.toLowerCase())}">
            ${tab}
          </div>
        `)}
      </div>
    `;
  }

  renderTabContent() {
    switch (this.activeTab) {
      case 'news':
        return html`
          <div class="tab-content">
            ${this.renderNewsFeed()}
          </div>
        `;
      case 'availability':
        return html`
          <app-header pageTitle="Availability 📅"></app-header>
          <h3 class="availability-header">Select your availability for the following events and fixtures:</h3>
          <div class="tab-content">
            <app-availability></app-availability>
          </div>
        `;
      case 'events':
        return html`
          <app-header pageTitle="Events 📈"></app-header>
          <h3 class="availability-header">Save events to your calendar or view them on maps:</h3>
          <div class="tab-content">
            <app-events></app-events>
          </div>
        `;
      default:
        return html`<div>Select a tab to view content.</div>`;
    }
  }

  renderNewsFeed() {
    return html`
      <app-header pageTitle="Cricket News 📰"></app-header>

      <div class="news-feed">
        ${this.newsItems.map((news) => {
          const date = new Date(news.date.seconds * 1000);
          const formattedDate = `${('0' + date.getDate()).slice(-2)}/${('0' + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`;
          return html`
            <div class="stat-card" @click="${() => this.openNews(news.id)}">
              <div class="stat-content">
                <div class="stat-value">${news.title}</div>
                <div class="stat-label">${news.content.substring(0, 100)}...</div>
                <div class="news-date">${formattedDate}</div>
              </div>
            </div>
          `;
        })}
      </div>
    `;
  }

  openNews(newsId: string) {
    router.navigate(`/news/${newsId}`);
  }

  renderImportantMessage() {
    return html``;
  }

  render() {
    return html`
      <app-header></app-header>
      <div class="top-stats">
        <div class="container">
          <div class="important-message-container">
           <button class="notify-btn" @click=${this.enableNotifications}>
          Enable Notifications
        </button>
            ${this.renderImportantMessage()}
          </div>
          ${this.renderTabs()}
          ${this.renderTabContent()}
        </div>
      </div>
    `;
  }
}
