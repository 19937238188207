import { LitElement, html, css, TemplateResult } from 'lit';
import { customElement, state, query } from 'lit/decorators.js';
import {
  getFirestore,
  doc,
  getDoc,
  getDocs,
  collection
} from 'firebase/firestore';
import { getAuth, onAuthStateChanged, Auth } from 'firebase/auth';
import Chart, { ChartConfiguration, ChartEvent } from 'chart.js/auto';
import { router, resolveRouterPath } from '../router';

interface BattingStats {
  total_ones: number;
  total_twos: number;
  total_threes: number;
  total_fours: number;
  total_sixes: number;
  total_outs: number;
  runs_scored: number;
  total_not_outs: number;
  stumped: number;
  caught: number;
  lbw: number;
  bowled: number;
  run_out: number;
  other: number;
  ducks: number;
  golden_ducks: number;
  fifties: number;
  hundreds: number;
  balls_faced: number;
}

@customElement('app-batting')
export class AppBatting extends LitElement {
  @state() private stats: BattingStats | null = null;
  @state() private isLoading: boolean = true;
  // Dynamically fetched available seasons from the /Seasons collection.
  @state() private availableSeasons: string[] = [];
  // Default selected season is "All"
  @state() private selectedSeason: string = 'All';
  @state() private interactiveMessage: string = '';

  private auth: Auth = getAuth();
  private db = getFirestore();

  // Chart instances
  private overviewChart?: Chart;
  private boundaryChart?: Chart;

  // Query selectors for canvas elements
  @query('#overviewCanvas') private overviewCanvas!: HTMLCanvasElement;
  @query('#boundaryCanvas') private boundaryCanvas!: HTMLCanvasElement;

  static styles = css`
    :host {
      display: block;
      background: #f9f9f9;
      padding: 16px;
      color: #333;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }
    .header {
      text-align: center;
      margin-bottom: 20px;
    }
    .header h1 {
      font-size: 2.5rem;
      color: #206e3a;
      margin: 0;
    }
    .header p {
      font-size: 1.2rem;
      color: #555;
      margin-top: 5px;
    }
    .dropdown-container {
      text-align: center;
      margin-bottom: 20px;
    }
    select {
      padding: 10px 15px;
      font-size: 1rem;
      border: 1px solid #ddd;
      border-radius: 5px;
      background-color: #206e3a;
      color: white;
      outline: none;

    }
    .stats-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      gap: 20px;
      margin: 20px 0;
    }
    .stat-card {
      background: white;
      border: 1px solid #ddd;
      border-radius: 8px;
      padding: 15px;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);
      text-align: center;
      cursor: pointer;
      transition: transform 0.2s;
    }
    .stat-card:hover {
      transform: translateY(-3px);
    }
    .stat-label {
      font-size: 0.9rem;
      color: #666;
      margin-bottom: 8px;
    }
    .stat-value {
      font-size: 1.5rem;
      font-weight: bold;
      color: #206e3a;
    }
    .charts-container {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: center;
      margin-bottom: 20px;
    }
    .chart-card {
      background: white;
      border-radius: 8px;
      box-shadow: 0 2px 6px rgba(0,0,0,0.1);
      padding: 16px;
      width: 100%;
      max-width: 400px;
      cursor: pointer;
      transition: transform 0.2s;
    }
    .chart-card:hover {
      transform: translateY(-5px);
    }
    canvas {
      width: 100% !important;
      height: 300px;
      border-radius: 8px;
    }
    .stats-details {
      text-align: center;
      margin-top: 10px;
    }
    .stats-details h2 {
      font-size: 2rem;
      color: #206e3a;
    }
    .stats-details p {
      font-size: 1.1rem;
      color: #555;
    }
    .back-button {
      display: block;
      margin: 30px auto;
      padding: 10px 20px;
      background-color: #206e3a;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 1rem;
      text-align: center;
    }
    .back-button:hover {
      background-color: #105e2a;
    }
    .interactive-message {
      text-align: center;
      font-size: 1.2rem;
      color: #206e3a;
      margin-top: 10px;
    }
    @media (max-width: 600px) {
      .stats-grid {
        grid-template-columns: repeat(2, 1fr);
      }
      .charts-container {
        flex-direction: column;
      }
    }
        .first-stat {
        padding-top: 115px; /* Adjust the value based on your header's height */
      }

  `;

  connectedCallback(): void {
    super.connectedCallback();
    this.fetchAvailableSeasons();
    onAuthStateChanged(this.auth, (user) => {
      if (user) {
        // Initially load aggregated stats ("All")
        this.fetchUserStats(user.uid, this.selectedSeason);
      }
    });
  }

  // Fetch available seasons from the /Seasons collection.
  async fetchAvailableSeasons(): Promise<void> {
    const seasonsCol = collection(this.db, 'Seasons');
    const snapshot = await getDocs(seasonsCol);
    const seasons: string[] = ['All']; // Start with the "All" option.
    snapshot.forEach((doc) => {
      seasons.push(doc.id);
    });
    // Optionally, sort seasons (e.g., descending order)
    this.availableSeasons = seasons.sort((a, b) => (a === 'All' ? -1 : b.localeCompare(a)));
  }

  /**
   * Fetch stats for the given user and season.
   * If season === 'All', data is fetched from 'Player/{uid}'.
   * Otherwise, data is fetched from 'Seasons/{season}/Players/{uid}'.
   */
  async fetchUserStats(uid: string, season: string): Promise<void> {
    let userRef;
    if (season === 'All') {
      userRef = doc(this.db, 'Player', uid);
    } else {
      userRef = doc(this.db, `Seasons/${season}/Players`, uid);
    }
    const docSnap = await getDoc(userRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      this.stats = {
        total_ones: data.total_ones ?? 0,
        total_twos: data.total_twos ?? 0,
        total_threes: data.total_threes ?? 0,
        total_fours: data.total_fours ?? 0,
        total_sixes: data.total_sixes ?? 0,
        total_outs: data.total_outs ?? 0,
        runs_scored: data.runs_scored ?? 0,
        total_not_outs: data.total_not_outs ?? 0,
        stumped: data.stumped ?? 0,
        caught: data.caught ?? 0,
        lbw: data.lbw ?? 0,
        bowled: data.bowled ?? 0,
        run_out: data.run_out ?? 0,
        other: data.other ?? 0,
        ducks: data.ducks ?? 0,
        golden_ducks: data.golden_ducks ?? 0,
        fifties: data.fifties ?? 0,
        hundreds: data.hundreds ?? 0,
        balls_faced: data.balls_faced ?? 0,
      };
    }
    this.isLoading = false;
    await this.updateComplete;
    this.updateCharts();
  }

  // Derived metrics
  get battingAverage(): string {
    if (!this.stats) return '--';
    const outs = this.stats.total_outs - this.stats.total_not_outs;
    return outs > 0 ? (this.stats.runs_scored / outs).toFixed(2) : '--';
  }

  get strikeRate(): string {
    if (!this.stats || this.stats.balls_faced <= 0) return '--';
    return ((this.stats.runs_scored / this.stats.balls_faced) * 100).toFixed(2);
  }

  updateCharts(): void {
    if (!this.stats) return;

    // Bar chart for Performance Overview.
    const overviewLabels = ['Runs', 'Balls', 'Avg', 'SR'];
    const overviewData = [
      this.stats.runs_scored,
      this.stats.balls_faced,
      isNaN(parseFloat(this.battingAverage)) ? 0 : parseFloat(this.battingAverage),
      isNaN(parseFloat(this.strikeRate)) ? 0 : parseFloat(this.strikeRate)
    ];
    if (this.overviewChart) {
      this.overviewChart.destroy();
    }
    const overviewCtx = this.overviewCanvas.getContext('2d');
    if (overviewCtx) {
      this.overviewChart = new Chart(overviewCtx, {
        type: 'bar',
        data: {
          labels: overviewLabels,
          datasets: [{
            label: 'Performance Overview',
            data: overviewData,
            backgroundColor: 'rgba(32, 110, 58, 0.6)',
            borderColor: 'rgba(32, 110, 58, 1)',
            borderWidth: 1
          }]
        },
        options: {
          responsive: true,
          onClick: (_evt: ChartEvent, elements) => {
            if (elements.length > 0) {
              const index = elements[0].index;
              const statClicked = overviewLabels[index];
              this.interactiveMessage = `You clicked on ${statClicked}. Tap any KPI box below for full details!`;
            }
          },
          scales: { y: { beginAtZero: true } }
        }
      });
    }

    // Doughnut chart for Boundary Breakdown.
    const boundaryLabels = ['1s', '2s', '3s', '4s', '6s'];
    const boundaryData = [
      this.stats.total_ones,
      this.stats.total_twos,
      this.stats.total_threes,
      this.stats.total_fours,
      this.stats.total_sixes
    ];
    if (this.boundaryChart) {
      this.boundaryChart.destroy();
    }
    const boundaryCtx = this.boundaryCanvas.getContext('2d');
    if (boundaryCtx) {
      const config: ChartConfiguration<'doughnut', number[], string> = {
        type: 'doughnut',
        data: {
          labels: boundaryLabels,
          datasets: [{
            label: 'Boundary Breakdown',
            data: boundaryData,
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF']
          }]
        },
        options: {
          responsive: true,
          plugins: { legend: { position: 'bottom' } }
        }
      };
      this.boundaryChart = new Chart(boundaryCtx, config as any);
    }
  }

  // Navigate to full batting stats page with the selected KPI field and season.
  private navigateToFullStats(kpiField: string): void {
    const route = resolveRouterPath('full-batting-stats');
    const queryParams = `?season=${this.selectedSeason}&field=${kpiField}`;
    router.navigate(route + queryParams);
  }

  renderSeasonDropdown(): TemplateResult {
    return html`
      <div class="dropdown-container">
        <select
          @change="${(e: Event) => {
            const select = e.target as HTMLSelectElement;
            this.selectedSeason = select.value;
            const user = this.auth.currentUser;
            if (user) {
              this.fetchUserStats(user.uid, this.selectedSeason);
            }
          }}"
        >
          ${this.availableSeasons.map(season => html`
            <option value="${season}" ?selected="${this.selectedSeason === season}">
              ${season === 'All' ? 'All Years' : season}
            </option>
          `)}
        </select>
      </div>
    `;
  }

  renderStatsGrid(): TemplateResult {
    if (!this.stats) return html``;
    return html`
    <app-header pageTitle="Batting" ></app-header>
      <div class="top-stats first-stat">
      <div class="stats-grid">
        <div class="stat-card" @click="${() => this.navigateToFullStats('runs_scored')}">
          <div class="stat-label">Runs Scored</div>
          <div class="stat-value">${this.stats.runs_scored}</div>
        </div>
        <div class="stat-card" @click="${() => this.navigateToFullStats('balls_faced')}">
          <div class="stat-label">Balls Faced</div>
          <div class="stat-value">${this.stats.balls_faced}</div>
        </div>
        <div class="stat-card" @click="${() => this.navigateToFullStats('batting_average')}">
          <div class="stat-label">Batting Average</div>
          <div class="stat-value">${this.battingAverage}</div>
        </div>
        <div class="stat-card" @click="${() => this.navigateToFullStats('strike_rate')}">
          <div class="stat-label">Strike Rate</div>
          <div class="stat-value">${this.strikeRate}</div>
        </div>
        <div class="stat-card" @click="${() => this.navigateToFullStats('fifties')}">
          <div class="stat-label">Fifties</div>
          <div class="stat-value">${this.stats.fifties}</div>
        </div>
        <div class="stat-card" @click="${() => this.navigateToFullStats('hundreds')}">
          <div class="stat-label">Hundreds</div>
          <div class="stat-value">${this.stats.hundreds}</div>
        </div>
        <!-- Add more KPI boxes as needed -->
      </div>
    `;
  }

  renderCharts(): TemplateResult {
    return html`
      <div class="charts-container">
        <div class="chart-card" @click="${() => router.navigate(resolveRouterPath('batting'))}">
          <canvas id="overviewCanvas" height="300"></canvas>
          <div class="stats-details">
            <h2>Performance Overview</h2>
            <p>Avg: ${this.battingAverage} | SR: ${this.strikeRate}</p>
          </div>
        </div>
        <div class="chart-card">
          <canvas id="boundaryCanvas" height="300"></canvas>
          <div class="stats-details">
            <h2>Boundary Breakdown</h2>
          </div>
        </div>
      </div>
    `;
  }

  private navigateBack(): void {
    router.navigate(resolveRouterPath('batting'));
  }

  renderDashboard(): TemplateResult {
    return html`
      <div class="header">
        <h1>Batting Dashboard</h1>
        <p>Detailed stats for the selected season</p>
      </div>
      ${this.renderSeasonDropdown()}
      ${this.renderStatsGrid()}
      ${this.renderCharts()}
      <button class="back-button" @click="${this.navigateBack}">Back to Main Dashboard</button>
      <div class="interactive-message">
        ${this.interactiveMessage}
      </div>
    `;
  }

  renderLoading(): TemplateResult {
    return html`<p>Loading batting stats...</p>`;
  }

  render(): TemplateResult {
    return html`
      ${this.isLoading || !this.stats ? this.renderLoading() : this.renderDashboard()}
    `;
  }
}
