
// docs for router https://github.com/thepassle/app-tools/blob/master/router/README.md
import { html } from 'lit';
if (!(globalThis as any).URLPattern) {
  await import("urlpattern-polyfill");
}
import { Router } from '@thepassle/app-tools/router.js';
import { lazy } from '@thepassle/app-tools/router/plugins/lazy.js';
// @ts-ignore
import { title } from '@thepassle/app-tools/router/plugins/title.js';
import './pages/app-home.js';

const baseURL: string = (import.meta as any).env.BASE_URL;
export const router = new Router({
    routes: [
      {
        path: resolveRouterPath(),
        title: 'Home',
        render: () => html`<app-home></app-home>`
      },
      {
        path: resolveRouterPath('login'),
        title: 'Login',
        plugins: [
          lazy(() => import('./pages/app-login.js')),
        ],
        render: () => html`<app-login></app-login>`
      },
      {
        path: resolveRouterPath('sign-up'),
        title: 'Sign up',
        plugins: [
          lazy(() => import('./pages/app-sign-up.js')),
        ],
        render: () => html`<app-sign-up></app-sign-up>`
      },
      {
        path: resolveRouterPath('availability'),
        title: 'Availability',
        plugins: [
          lazy(() => import('./pages/app-availability.js')),
        ],
        render: () => html`<app-availability></app-availability>`
      },
      {
        path: resolveRouterPath('fixtures'),
        title: 'Fixtures',
        plugins: [
          lazy(() => import('./pages/app-fixtures.js')),
        ],
        render: () => html`<app-fixtures></app-fixtures>`
      },
      {
        path: resolveRouterPath('results'),
        title: 'Results',
        plugins: [
          lazy(() => import('./pages/app-results.js')),
        ],
        render: () => html`<app-results></app-results>`
      },
      {
        path: resolveRouterPath('batting'),
        title: 'Batting',
        plugins: [
          lazy(() => import('./pages/app-batting.js')),
        ],
        render: () => html`<app-batting></app-batting>`
      },
      {
        path: resolveRouterPath('bowling'),
        title: 'Bowling',
        plugins: [
          lazy(() => import('./pages/app-bowling.js')),
        ],
        render: () => html`<app-bowling></app-bowling>`
      },
      {
        path: resolveRouterPath('fielding'),
        title: 'Fielding',
        plugins: [
          lazy(() => import('./pages/app-fielding.js')),
        ],
        render: () => html`<app-fielding></app-fielding>`
      },
      {
        path: resolveRouterPath('leaderboard'),
        title: 'Leaderboard',
        plugins: [
          lazy(() => import('./pages/app-leaderboard.js')),
        ],
        render: () => html`<app-leaderboard></app-leaderboard>`
      },
      {
        path: resolveRouterPath('badges'),
        title: 'Badges',
        plugins: [
          lazy(() => import('./pages/app-badges.js')),
        ],
        render: () => html`<app-badges></app-badges>`
      },
      {
        path: resolveRouterPath('team-detail/:teamId'),
        title: 'My Team',
        plugins: [
          lazy(() => import('./pages/app-team-detail.js')),
        ],
        render: (context) => html`<app-team-detail .teamId=${context.params.teamId}></app-team-detail>`
      },
      {
        path: resolveRouterPath('my-team'),
        title: 'My Team',
        plugins: [
          lazy(() => import('./pages/app-my-team.js')),
        ],
        render: () => html`<app-my-team></app-my-team>`
      },
      {
        path: resolveRouterPath('league'),
        title: 'League',
        plugins: [
          lazy(() => import('./pages/app-league.js')),
        ],
        render: () => html`<app-league></app-league>`
      },
      {
        path: resolveRouterPath('profile'),
        title: 'Profile',
        plugins: [
          lazy(() => import('./pages/app-profile.js')),
        ],
        render: () => html`<app-profile></app-profile>`
      },
      {
        path: resolveRouterPath('scorer'),
        title: 'Scorer',
        plugins: [
          lazy(() => import('./pages/app-scorer.js')),
        ],
        render: () => html`<app-scorer></app-scorer>`
      },
      {
        path: resolveRouterPath('game-settings'),
        title: 'Game Settings',
        plugins: [
          lazy(() => import('./pages/app-game-settings.js')),
        ],
        render: () => html`<app-game-settings></app-game-settings>`
      },
      {
        path: resolveRouterPath('select-fixture-page'),
        title: 'Select Fixture Page',
        plugins: [
          lazy(() => import('./pages/app-select-fixture-page.js')),
        ],
        render: () => html`<app-select-fixture-page></app-select-fixture-page>`
      },
      {
        path: resolveRouterPath('selected-team-page'),
        title: 'Select Team Page',
        plugins: [
          lazy(() => import('./pages/app-selected-team-page.js')),
        ],
        render: () => html`<app-selected-team-page></app-selected-team-page>`
      },
      {
        path: resolveRouterPath('selected-oppo-team'),
        title: 'Select Opponent Team',
        plugins: [
          lazy(() => import('./pages/app-selected-oppo-team.js')),
        ],
        render: () => html`<app-selected-oppo-team></app-selected-oppo-team>`
      },
      {
        path: resolveRouterPath('welcome-page'),
        title: 'Welcome Page',
        plugins: [
          lazy(() => import('./pages/app-welcome-page.js')),
        ],
        render: () => html`<app-welcome-page></app-welcome-page>`
      },
      {
        path: resolveRouterPath('selection'),
        title: 'Selection',
        plugins: [
          lazy(() => import('./pages/app-selection.js')),
        ],
        render: () => html`<app-selection></app-selection>`
      },
      {
        path: resolveRouterPath('fixture-details/:fixtureId'),
        title: 'Fixture Details',
        plugins: [
          lazy(() => import('./pages/app-fixture-details.js')),
        ],
        render: (context) => html`<app-fixture-details .fixtureId=${context.params.fixtureId}></app-fixture-details>`
      },

      {
        path: resolveRouterPath('enter-fixture'),
        title: 'Enter Fixture',
        plugins: [
          lazy(() => import('./pages/app-enter-fixture.js')),
        ],
        render: () => html`<app-enter-fixture></app-enter-fixture>`
      },
      {
        path: resolveRouterPath('results-fixtures-carousel'),
        title: 'Enter Fixture',
        plugins: [
          lazy(() => import('./pages/app-results-fixtures-carousel.js')),
        ],
        render: () => html`<app-results-fixtures-carousel></app-results-fixtures-carousel>`
      },

    {
      path: resolveRouterPath('full-catching-chart'),
      title: 'Full Catching Chart',
      plugins: [
        lazy(() => import('./pages/app-full-catching-chart.js')),
      ],
      render: () => html`<app-full-catching-chart></app-full-catching-chart>`
    },
    {
      path: resolveRouterPath('full-stumping-chart'),
      title: 'Full Stumping Chart',
      plugins: [
        lazy(() => import('./pages/app-full-stumping-chart.js')),
      ],
      render: () => html`<app-full-stumping-chart></app-full-stumping-chart>`
    },
    {
      path: resolveRouterPath('full-runout-chart'),
      title: 'Full Runout Chart',
      plugins: [
        lazy(() => import('./pages/app-full-runout-chart.js')),
      ],
      render: () => html`<app-full-runout-chart></app-full-runout-chart>`
    },
      {
        path: resolveRouterPath('full-dismissals-chart'),
        title: 'Full Dismissals Chart',
        plugins: [
          lazy(() => import('./pages/app-full-dismissals-chart.js')),
        ],
        render: () => html`<app-full-dismissals-chart></app-full-dismissals-chart>`
      },

      {
        path: resolveRouterPath('full-distribution-chart'),
        title: 'Full Distribution Chart',
        plugins: [
          lazy(() => import('./pages/app-full-distribution-chart.js')),
        ],
        render: () => html`<app-full-distribution-chart></app-full-distribution-chart>`
      },

      {
        path: resolveRouterPath('terms'),
        title: 'Terms',
        plugins: [
          lazy(() => import('./pages/app-terms.js')),
        ],
        render: () => html`<app-terms></app-terms>`
      },
      {
        path: resolveRouterPath('fantasy-rules'),
        title: 'Fantasy-Rules',
        plugins: [
          lazy(() => import('./pages/app-fantasy-rules.js')),
        ],
        render: () => html`<app-fantasy-rules></app-fantasy-rules>`
      },
      {
        path: resolveRouterPath('stats-entry'),
        title: 'Stat Entry',
        plugins: [
          lazy(() => import('./pages/app-stats-entry.js')),
        ],
        render: () => html`<app-stats-entry></app-stats-entry>`
      },

      {
        path: resolveRouterPath('score-entry'),
        title: 'Stat Entry',
        plugins: [
          lazy(() => import('./pages/app-score-entry.js')),
        ],
        render: () => html`<app-score-entry></app-score-entry>`
      },
      {
        path: resolveRouterPath('fantasy-entry'),
        title: 'Fantasy Entry',
        plugins: [
          lazy(() => import('./pages/app-fantasy-entry.js')),
        ],
        render: () => html`<app-fantasy-entry></app-fantasy-entry>`
      },
      {
        path: resolveRouterPath('badge-detail'),
        title: 'Badge Details',
        plugins: [
          lazy(() => import('./pages/app-badge-detail.js')),
        ],
        render: () => html`<app-badge-detail></app-badge-detail>`
      },
      {
        path: resolveRouterPath('merge-player-data'),
        title: 'Merge Player Data',
        plugins: [
          lazy(() => import('./pages/app-merge-player-data.js')),
        ],
        render: () => html`<app-merge-player-data></app-merge-player-data>`
      },
      {
        path: resolveRouterPath('stats-upload'),
        title: 'Stats Upload',
        plugins: [
          lazy(() => import('./pages/app-stats-upload.js')),
        ],
        render: () => html`<app-stats-upload></app-stats-upload>`
      },
      {
        path: resolveRouterPath('scorecard/:fixtureId'),
        title: 'Scorecard',
        plugins: [
          lazy(() => import('./pages/app-scorecard.js')),
        ],
        render: (context) => html`<app-scorecard .fixtureId=${context.params.fixtureId}></app-scorecard>`
      },

      {
        path: resolveRouterPath('finance'),
        title: 'Finance',
        plugins: [
          lazy(() => import('./pages/app-finance.js')),
        ],
        render: () => html`<app-finance></app-finance>`
      },
      {
        path: resolveRouterPath('club-finance'),
        title: 'Club Finance',
        plugins: [
          lazy(() => import('./pages/app-club-finance.js')),
        ],
        render: () => html`<app-club-finance></app-club-finance>`
      },

      {
        path: resolveRouterPath('user-finance'),
        title: 'User Finance',
        plugins: [
          lazy(() => import('./pages/app-user-finance.js')),
        ],
        render: () => html`<app-user-finance></app-user-finance>`
      },

      {
        path: resolveRouterPath('laws'),
        title: 'Laws',
        plugins: [
          lazy(() => import('./pages/app-laws.js')),
        ],
        render: () => html`<app-laws></app-laws>`
      },

      {
        path: resolveRouterPath('store'),
        title: 'Store',
        plugins: [
          lazy(() => import('./pages/app-store.js')),
        ],
        render: () => html`<app-store></app-store>`
      },

      {
        path: resolveRouterPath('fine-entry'),
        title: 'Fine Entry',
        plugins: [
          lazy(() => import('./pages/app-fine-entry.js')),
        ],
        render: () => html`<app-fine-entry></app-fine-entry>`
      },

      {
        path: resolveRouterPath('news-entry'),
        title: 'News Entry',
        plugins: [
          lazy(() => import('./pages/app-news-entry.js')),
        ],
        render: () => html`<app-news-entry></app-news-entry>`
      },

      {
        path: resolveRouterPath('news/:newsId'),
        title: 'News Details',
        plugins: [
          lazy(() => import('./pages/app-news-details.js')),
        ],
        render: (context) => html`<app-news-details .newsId=${context.params.newsId}></app-news-details>`
      },

      {
        path: resolveRouterPath('awards'),
        title: 'Awards',
        plugins: [
          lazy(() => import('./pages/app-awards.js')),
        ],
        render: () => html`<app-awards></app-awards>`
      },

      {
        path: resolveRouterPath('events'),
        title: 'Events',
        plugins: [
          lazy(() => import('./pages/app-events.js')),
        ],
        render: () => html`<app-events></app-events>`
      },
      {
        path: resolveRouterPath('enter-event'),
        title: 'Enter Event',
        plugins: [
          lazy(() => import('./pages/app-enter-event.js')),
        ],
        render: () => html`<app-enter-event></app-enter-event>`
      },
      {
        path: resolveRouterPath('support-ticket'),
        title: 'Support Ticket',
        plugins: [
          lazy(() => import('./pages/app-support-ticket.js')),
        ],
        render: () => html`<app-support-ticket></app-support-ticket>`
      },
      {
        path: resolveRouterPath('support-admin'),
        title: 'Support Admin',
        plugins: [
          lazy(() => import('./pages/app-support-admin.js')),
        ],
        render: () => html`<app-support-admin></app-support-admin>`
      },

      {
        path: resolveRouterPath('fines'),
        title: 'Fines',
        plugins: [
          lazy(() => import('./pages/app-fines.js')),
        ],
        render: () => html`<app-fines></app-fines>`
      },

      {
        path: resolveRouterPath('full-batting-stats'),
        title: 'Full Batting Stats',
        plugins: [
          lazy(() => import('./pages/app-full-batting-stats')),
        ],
        render: () => html`<app-full-batting-stats></app-full-batting-stats>`
      },

      {
        path: resolveRouterPath('full-bowling-stats'),
        title: 'Full Bowling Stats',
        plugins: [
          lazy(() => import('./pages/app-full-bowling-stats')),
        ],
        render: () => html`<app-full-bowling-stats></app-full-bowling-stats>`
      },


      {
        path: resolveRouterPath('admin-update-strike-rate'),
        title: 'ds Batting Stats',
        plugins: [
          lazy(() => import('./pages/app-admin-update-strike-rate')),
        ],
        render: () => html`<app-admin-update-strike-rate></app-admin-update-strike-rate>`
      },

      {
        path: resolveRouterPath('admin'),
        title: 'Admin',
        plugins: [
          lazy(() => import('./pages/app-admin.js')),
        ],
        render: () => html`<app-admin></app-admin>`
      },

    ]
  });
  // This function will resolve a path with whatever Base URL was passed to the vite build process.
  // Use of this function throughout the starter is not required, but highly recommended, especially if you plan to use GitHub Pages to deploy.
  // If no arg is passed to this function, it will return the base URL.
  export function resolveRouterPath(unresolvedPath?: string) {
    var resolvedPath = baseURL;
    if(unresolvedPath) {
      resolvedPath = resolvedPath + unresolvedPath;
    }
    return resolvedPath;
  }
