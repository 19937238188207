// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";

//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDajP2PUFvM87E4fj7FZ42_TWqVXgckhUU",
  authDomain: "appletoncc-6261b.firebaseapp.com",
  databaseURL: "https://appletoncc-6261b-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "appletoncc-6261b",
  storageBucket: "appletoncc-6261b.appspot.com",
  messagingSenderId: "652074739848",
  appId: "1:652074739848:web:2444ac74b8e6bef226973d",
  measurementId: "G-LG4W1W5MLX"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const messaging = getMessaging(app);
export const auth = getAuth(app);  // Initialize Firebase auth to get the user

// Function to save token to Firestore
async function saveTokenToFirestore(token: string, userId: string) {
  const userRef = doc(db, 'Player', userId); // Adjust to your Firestore structure
  await setDoc(userRef, { fcmToken: token }, { merge: true });
}

// Function to request notification permission
export async function requestPermission() {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging, { vapidKey: 'BJ1syL_LZkDUHEDzeQQ1MCk3mxW-TgZVRV5750BCD0nBqtfy8C34Ea-JyTyrEf0OYrQYu-_UynwUkFzDeODBcHU' });
      console.log('Notification token:', token);

      // Check if a user is logged in
      const user = auth.currentUser;
      if (user && token) {
        const userId = user.uid;  // Use the logged-in user's UID
        await saveTokenToFirestore(token, userId);  // Save token to Firestore
        console.log('Token saved to Firestore for user:', userId);
      }
    } else {
      console.error("Permission not granted for notifications");
    }
  } catch (error) {
    console.error("Error getting notification permission:", error);
  }
}