import { LitElement, html, css, TemplateResult } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import {
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  getDocs,
  getDoc,
  doc,
  setDoc,
  Timestamp
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { classMap } from 'lit/directives/class-map.js';

@customElement('app-availability')
export class AppAvailability extends LitElement {
  @state() private items: any[] = []; // Holds both fixtures and events
  @state() private activeSeasonId: string = '';

  private db = getFirestore();
  private auth = getAuth();

  static styles = css`
    :host {
      color: #333;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      padding: 20px;
      display: block;
    }
 .item {
  display: flex;
  flex-direction: column; /* stack on small screens */
  padding: 15px;
  margin-bottom: 10px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  transition: background-color 0.3s;
}

.item-actions {
  display: flex;
  gap: 8px; /* space between buttons */
}

@media (min-width: 600px) {
  .item {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .item-info {
    margin-bottom: 0;
    margin-right: 20px; /* some spacing between text and buttons */
  }
}
    .item:hover {
      transform: translateY(-2px);
    }
    .item-info {
      flex-grow: 1;
      margin-right: 20px;
      color: #444;
    }
    .item-info div {
      margin-bottom: 4px;
    }
    .item-date, .item-opponent {
      font-size: 0.95em;
      color: #666;
    }
    .item-opponent {
      font-size: 1.1em;
      font-weight: bold;
      color: #333;
    }
    .item-venue, .item-time {
      font-size: 0.9em;
      color: #777;
    }

    .available {
      background-color: #4CAF50;
      color: white;
    }
    .not-available {
      background-color: #F44336;
      color: white;
    }
  .availability-toggle,
.register-button {
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.2s;
  text-align: center;
  margin-left: 5px;

  /* Enforce a consistent size */
  min-width: 130px; /* Adjust to your preference */
}

    .available:hover,
    .not-available:hover,
    .register-button:hover {
      opacity: 0.85;
      transform: translateY(-2px);
    }
    .first-stat {
      padding-top: 100px;
    }
    .availability-header {
      text-align: center;
      padding: 16px;
      margin-bottom: 32px;
    }
    .availability-title {
      font-size: 1.8rem;
      color: #206e3a;
    }
    .availability-description {
      font-size: 1rem;
      color: #555;
      margin-top: 8px;
    }
    .no-fixtures-message {
      text-align: center;
      font-size: 1.5rem;
      color: #666;
      margin-top: 20px;
    }
  `;

  connectedCallback(): void {
    super.connectedCallback();
    this.fetchItems();
  }

  async fetchItems(): Promise<void> {
    try {
      // Query for the active season (where endseason == false)
      const seasonsRef = collection(this.db, 'Seasons');
      const activeSeasonQuery = query(seasonsRef, where('endseason', '==', false));
      const seasonSnapshot = await getDocs(activeSeasonQuery);
      if (seasonSnapshot.empty) {
        console.error("No active season found.");
        return;
      }
      const activeSeasonDoc = seasonSnapshot.docs[0];
      this.activeSeasonId = activeSeasonDoc.id;
      console.log("Active season:", this.activeSeasonId);

      // Build collection refs for fixtures and events under the active season.
      // For fixtures, use the field "fixtureDate".
      const fixturesRef = collection(this.db, `Seasons/${this.activeSeasonId}/Fixtures`);
      const fixturesQuery = query(fixturesRef, where('fixtureDate', '>', new Date()), orderBy('fixtureDate', 'asc'));
      const fixturesSnapshot = await getDocs(fixturesQuery);

      // For events, assume the field "date".
      const eventsRef = collection(this.db, `Seasons/${this.activeSeasonId}/Events`);
      const eventsQuery = query(eventsRef, where('date', '>', new Date()), orderBy('date', 'asc'));
      const eventsSnapshot = await getDocs(eventsQuery);

      const user = this.auth.currentUser;

      // Process fixtures.
      const fixturePromises = fixturesSnapshot.docs.map(async (docSnapshot) => {
        const data = docSnapshot.data();
        let isAvailable = undefined;
        if (user) {
          const userAvailabilityRef = doc(this.db, `Seasons/${this.activeSeasonId}/Fixtures/${docSnapshot.id}/playerlist/${user.uid}`);
          const userAvailabilityDoc = await getDoc(userAvailabilityRef);
          if (userAvailabilityDoc.exists()) {
            isAvailable = userAvailabilityDoc.data().availability;
          }
        }
        return {
          id: docSnapshot.id,
          date: data.fixtureDate ? (data.fixtureDate as Timestamp).toDate() : new Date(),
          opponent: data.opponent,
          venue: data.venue,
          time: data.time,
          isAvailable,
          type: 'fixture'
        };
      });

      // Process events.
      const eventPromises = eventsSnapshot.docs.map(async (docSnapshot) => {
        const data = docSnapshot.data();
        let isAvailable = undefined;
        if (user) {
          const userAvailabilityRef = doc(this.db, `Seasons/${this.activeSeasonId}/Events/${docSnapshot.id}/playerlist/${user.uid}`);
          const userAvailabilityDoc = await getDoc(userAvailabilityRef);
          if (userAvailabilityDoc.exists()) {
            isAvailable = userAvailabilityDoc.data().availability;
          }
        }
        return {
          id: docSnapshot.id,
          date: data.date ? (data.date as Timestamp).toDate() : new Date(),
          event: data.event,
          address: data.address,
          time: data.time,
          isAvailable,
          type: 'event'
        };
      });

      const fixtures = await Promise.all(fixturePromises);
      const events = await Promise.all(eventPromises);
      this.items = [...fixtures, ...events].sort((a, b) => a.date.getTime() - b.date.getTime());
      console.log("Fetched items:", this.items);
    } catch (error) {
      console.error("Error fetching availability items:", error);
    } finally {
      this.requestUpdate();
    }
  }

  // If an availability document doesn't exist, auto-register it.
  async autoAddAvailability(itemId: string, type: string): Promise<void> {
    const user = this.auth.currentUser;
    if (!user) {
      console.error("No user is signed in.");
      return;
    }
    const collectionType = type === 'fixture'
      ? `Seasons/${this.activeSeasonId}/Fixtures`
      : `Seasons/${this.activeSeasonId}/Events`;
    const userAvailabilityRef = doc(this.db, `${collectionType}/${itemId}/playerlist/${user.uid}`);
    try {
      await setDoc(userAvailabilityRef, { availability: false }, { merge: true });
      this.items = this.items.map(item => {
        if (item.id === itemId) {
          return { ...item, isAvailable: false };
        }
        return item;
      });
      this.requestUpdate();
    } catch (error) {
      console.error("Error auto-adding availability:", error);
    }
  }

  async toggleAvailability(itemId: string, isAvailable: boolean, type: string): Promise<void> {
    const user = this.auth.currentUser;
    if (!user) {
      console.error("No user is signed in.");
      return;
    }
    const collectionType = type === 'fixture'
      ? `Seasons/${this.activeSeasonId}/Fixtures`
      : `Seasons/${this.activeSeasonId}/Events`;
    const userAvailabilityRef = doc(this.db, `${collectionType}/${itemId}/playerlist/${user.uid}`);
    try {
      await setDoc(userAvailabilityRef, { availability: isAvailable }, { merge: true });
      this.items = this.items.map(item => {
        if (item.id === itemId) {
          return { ...item, isAvailable };
        }
        return item;
      });
      this.requestUpdate();
    } catch (error) {
      console.error("Error updating availability:", error);
    }
  }

  render(): TemplateResult {
    return html`
      <app-header pageTitle="Availability ✔️"></app-header>
      <div class="top-stats first-stat">
        ${this.items.length > 0
          ? this.items.map(item => html`
              <div class=${classMap({ 'item': true, 'fixture': item.type === 'fixture', 'event': item.type === 'event' })}>
               <div class="item">
  <div class="item-info">
    <div class="item-date">${item.date.toDateString()}</div>
    ${item.type === 'fixture'
      ? html`
          <div class="item-opponent">${item.opponent}</div>
          <div class="item-venue">Venue: ${item.venue}</div>
        `
      : html`
          <div class="item-opponent">${item.event}</div>
          <div class="item-venue">Address: ${item.address}</div>
        `}
    <div class="item-time">Time: ${item.time}</div>
  </div>

  <div class="item-actions">
    ${item.isAvailable === undefined
      ? html`
          <button class="register-button"
            @click="${() => this.autoAddAvailability(item.id, item.type)}">
            Register Availability
          </button>
        `
      : html`
          <button
            class=${classMap({ 'availability-toggle': true, available: item.isAvailable === true })}
            @click="${() => this.toggleAvailability(item.id, true, item.type)}">
            Available
          </button>
          <button
            class=${classMap({ 'availability-toggle': true, 'not-available': item.isAvailable === false })}
            @click="${() => this.toggleAvailability(item.id, false, item.type)}">
            Not Available
          </button>
        `}
  </div>
</div>

            `)
          : html`
              <div class="no-fixtures-message">
                <p>No availability items found.</p>
              </div>
            `}
      </div>
    `;
  }
}
